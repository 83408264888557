import React from "react";
import theme from "theme";
import { Theme, Text, Strong, Link, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contact"} />
		<Helmet>
			<title>
				Bos Balance: Financial Expert for Expats & Freelancers in the Netherlands
			</title>
			<meta name={"description"} content={"Tailored financial and tax services for expats and freelancers in the Netherlands. Expert support in bookkeeping, VAT, and income tax by a qualified Chartered Accountant. Achieve financial clarity with Bos Balance."} />
			<meta property={"og:title"} content={"Bos Balance: Financial Expert for Expats & Freelancers in Netherlands"} />
			<meta property={"og:description"} content={"Tailored financial and tax services for expats and freelancers in the Netherlands. Expert support in bookkeeping, VAT, and income tax by a qualified Chartered Accountant. Achieve financial clarity with Bos Balance."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65bf71cb867c86001f1b6705/images/logo-whitefilled.png?v=2024-02-04T11:57:10.515Z"} />
		</Helmet>
		<Components.TopNavBar>
			<Override slot="link" />
		</Components.TopNavBar>
		<Section background="--color-light">
			<Override slot="SectionContent" display="grid" grid-template-columns="repeat(2, 1fr)" sm-grid-template-columns="repeat(1, 1fr)" />
			<Box min-width="100px" min-height="100px" padding="30px 30px 30px 30px">
				<Text margin="0px 0px 30px 0px" font="--headline2" color="--primary" as="h1">
					Get in Touch with Bos Balance
				</Text>
				<Text margin="0px 0px 0px 0px" font="--base" color="--primary">
					Whether you're taking your first steps in the Netherlands or looking to streamline your financial processes, I'm
			here to help. At Bos Balance, you'll find not just expert financial services, but a partnership that values your
			individual journey.
					<br />
					<br />
					If you have any questions, need assistance with financial matters, or want to start a conversation about how I can support your financial well-being, please feel free to reach out. Fill in the contact form below or use the provided details to get in touch. I look forward to hearing from you and exploring how we can bring balance to your financial life.
				</Text>
				<Text margin="30px 0px 0px 0px" font="--base" color="--primary">
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Phone:
					</Strong>
					<Link href="tel:+31616555767" color="--primary" target="_blank">
						+31 (0) 616 555 767
					</Link>
				</Text>
				<Text margin="30px 0px 0px 0px" font="--base" color="--primary">
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Email:{" "}
						<Link href="mailto:hello@bosbalance.com" color="--primary" target="_blank">
							hello@bosbalance.com
						</Link>
					</Strong>
				</Text>
				<Text margin="30px 0px 0px 0px" font="--base" color="--primary">
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Company registration number: 95584056
					</Strong>
				</Text>
			</Box>
			<Box
				lg-margin="20px 0px 0px 0px"
				padding="36px 36px 48px 36px"
				background="--color-darkL2"
				md-padding="28px 20px 28px 20px"
				sm-padding="28px 30px 28px 30px"
				lg-width="100%"
				id="form"
			>
				<Components.EmailForm>
					<Override slot="SuccessMessage" font="--lead" color="--light" />
					<Override slot="LoadingMessage" font="--lead" color="--light" />
					<Override slot="ErrorMessage" font="--lead" color="--light" />
				</Components.EmailForm>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="text" />
			<Override slot="text2" />
			<Override slot="text4">
				© 2024 BOS BALANCE BV. All rights reserved.
			</Override>
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65bf71cb867c86001f1b6703"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});